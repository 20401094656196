<template>
  <p class="display-2">
    No post was located with the title "{{ title }}" 😬
  </p>
</template>

<script>
export default {
  computed: {
    title() {
      return this.$route.params.title.split('-').join(' ')
    }
  }
}
</script>